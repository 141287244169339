import * as React from 'react';
import { graphql } from 'gatsby';
import { Grid, Container } from '@material-ui/core';
// import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { ContentfulCarteraTecnico } from '../../types/graphql-types';
import FlashLayout from '../components/FlashLayout';
import IconTitle from '../components/IconTitle';
import { useCarteraTecnicoPost } from '../operations/queries';
import getCategoryColor from '../utils/getCategoryColor';
import { Cartera as CarteraProps } from '../models/Cartera';
import options from '../utils/getRichTextOptions';
import Index from '../assets/icons/icon-Index.svg';
import Airtable from '../components/Airtable';
import StickyBox from 'react-sticky-box';
import LightboxCarousel from '../components/LightboxCarousel';
import PDFPreview from '../components/PdfPreview';
import { renderRichText } from "gatsby-source-contentful/rich-text"
import useIsClient from '../utils/isClient';
import { HeadApi } from '../components/HeadApi';

interface Props {
  data: {
    contentfulCarteraTecnico: ContentfulCarteraTecnico;
  };
}

export const Head = ({data}) => {
  const post: CarteraProps = useCarteraTecnicoPost(
    data.contentfulCarteraTecnico
  );
  // console.log("[POST HEAD]", post);
  return(
    <HeadApi
      title={post.title}
      description={post.description}
      img={post.fluid?.gatsbyImageData.images.fallback.src}
    />
  );
};

export default function CarteraTecnico({ data }: Props): JSX.Element {
  const color = getCategoryColor('tecnico');
  const post: CarteraProps = useCarteraTecnicoPost(
    data.contentfulCarteraTecnico
  );

  // console.log(post.tables);
  const isClient = useIsClient();

  if(isClient){

    return (
      <FlashLayout post={post} color={color}>
        <>
          <>
            <h2>{post.description}</h2>
          </>
          <Grid container>
            <Grid
              // style={{ paddingRight: '10px', paddingLeft: '10px' }}
              item
              xs={12}
              sm={12}
              md={post?.tables ? 4 : 3}
            >
              <StickyBox offsetTop={100} style={{ width: '300px' }}>
                {post?.tables &&
                  post?.tables.map((tabla: TableProps) => {
                    if (tabla.tipo == 'tarjeta') {
                      //tarjeta
                      return (
                        <Container>
                          <IconTitle icon={Index} title={tabla.titulo}>
                            <Airtable
                              src={tabla.tabla}
                              description={tabla.descripcion}
                              card={true}
                            />
                          </IconTitle>
                        </Container>
                      );
                    }
                  })}
              </StickyBox>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              style={{ background: 'white', marginTop: 20 }}
            >
              {post.text && renderRichText(post.text, options)}
      
              {post.images && <LightboxCarousel images={post.images} />}
              {post.pdf && (
                <div>
                  <PDFPreview pdf={post.pdf} />
                </div>
              )}
              {post.tables &&
                post.tables.map((tabla: TableProps) => {
                  if (tabla.tipo == '50%') {
                    return (
                      <>
                        <IconTitle icon={Index} title={tabla.titulo}>
                          <Airtable
                            src={tabla.tabla}
                            description={tabla.descripcion}
                            card={false}
                          />
                        </IconTitle>
                      </>
                    );
                  }
                })}
            </Grid>
          </Grid>
          <>
            {post.tables &&
              post.tables.map((tabla: TableProps) => {
                if (tabla.tipo == '100%') {
                  return (
                    <>
                      <Grid item xs={12} sm={12} md={6}></Grid>
                      <IconTitle icon={Index} title={tabla.titulo}>
                        <Airtable
                          src={tabla.tabla}
                          description={tabla.descripcion}
                          card={false}
                        />
                      </IconTitle>
                    </>
                  );
                }
              })}
          </>
        </>
      </FlashLayout>
    );
  }else{
    return(<></>)
  }
}

export const pageQuery = graphql`
  query($slug: String!) {
    contentfulCarteraTecnico(slug: { eq: $slug }) {
      ...carteraTecnico
    }
  }
`;
